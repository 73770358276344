
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { getMenu } from '@/api/menu';
import BoardField from '@/components/board/field.vue';
import {
  addPost,
  checkPostPassword,
  getPost,
  updatePost,
  updateSecretPost,
} from '@/api/post';
import { uploadFile } from '@/api/attachedFile';
import {
  IMenuDetail,
  IPost,
  IPostAdd,
  IPostCategory,
  IPostUpdate,
} from '@/types';
import { IBoardCategory } from '@/types/boardCategory';
import { UserModule } from '@/store/user';

@Component({
  name: 'BoardForm',
  components: {
    BoardField,
  },
})
export default class extends Vue {
  @Watch('$route')
  private handleChangeMenu() {
    this.init();
  }

  mounted() {
    this.init();
  }

  get isAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  private menu: IMenuDetail | null = null;

  private formData: IPostAdd | IPostUpdate = {
    boardUid: '',
    parentUid: null,
    writer: '',
    password: '',
    categoryList: [],
    dataList: [],
    fileList: [],
    noticeState: false,
  };

  private selectCategory: IBoardCategory | null = null;

  private handleChangeInputValue(value: string, index: number) {
    this.formData.dataList[index].inputValue = value;
  }

  private init() {
    this.formData = {
      boardUid: '',
      parentUid: null,
      writer: '',
      password: '',
      categoryList: [],
      dataList: [],
      fileList: [],
      noticeState: false,
    };
    const load = this.$loading.show();
    getMenu(this.$route.params.menuUid).then((res: any) => {
      this.menu = res.data;
      if (res.data.board.authWrite === 'GUEST' && this.$route.params.postUid && !this.isAdmin) {
        if (String(this.$route.query.password)) {
          checkPostPassword(this.$route.params.postUid, String(this.$route.query.password)).then((response) => {
            if (!response.data) this.$router.push({ name: 'BoardIndex', params: { menuUid: this.$route.params.menuUid } });
          });
        }
      }
      if (this.menu) this.formData.boardUid = this.menu.board.uid;
      if (!this.$route.params.postUid && this.menu) {
        this.menu.board.fieldList.forEach((boardField: any) => {
          const postData: any = {
            postDataPk: {
              postUid: '' || this.$route.params.postUid,
              field: boardField,
            },
            inputValue: '',
          };
          this.formData.dataList.push(postData);
          load.hide();
        });
        if (this.$route.query.parentUid) {
          getPost(String(this.$route.query.parentUid)).then((response) => {
            (this.$refs.boardField as any)[0].handleChangeContents(`Re : ${response.data.dataList[0].inputValue}`);
            this.formData.parentUid = response.data.uid;
          });
          load.hide();
        }
      } else {
        getPost(this.$route.params.postUid).then((response: any) => {
          this.formData = response.data;
          if (response.data.categoryList.length > 0) {
            this.selectCategory = response.data.categoryList[0].postCategoryPk.category;
          }
          if (this.menu && response.data.dataList.length !== this.menu.board.fieldList) {
            const newDataList: any[] = [];
            this.menu.board.fieldList.forEach((boardField: any, index: number) => {
              const dataIndex = response.data.dataList.findIndex((data: any) => data.postDataPk.field.uid === boardField.uid);
              if (dataIndex < 0) {
                const postData: any = {
                  postDataPk: {
                    postUid: '' || this.$route.params.postUid,
                    field: boardField,
                  },
                  inputValue: '',
                };
                newDataList.push(postData);
              } else {
                newDataList.push(response.data.dataList[dataIndex]);
              }
            });
            this.formData.dataList = newDataList;
          }
          load.hide();
        });
      }
    });
  }

  private handleClickSubmit() {
    let valid = true;
    (this.$refs.boardField as any).forEach((field: any) => {
      field.$validator.validate().then((fieldValid: boolean) => {
        valid = valid && fieldValid;
      });
    });
    this.$validator.validate().then((valid2: boolean) => {
      valid = valid && valid2;
    });
    setTimeout(() => {
      if (valid) {
        const load = this.$loading.show();
        this.convertCategory();
        if (this.$route.params.postUid) {
          if (this.$route.query.password) {
            updateSecretPost(this.$route.params.postUid, this.formData, String(this.$route.query.password)).then((res) => {
              load.hide();
              this.$router.push({ name: this.$route.name === 'AppPostUpdate' ? 'AppPostDetail' : 'PostDetail', params: { menuUid: this.$route.params.menuUid, postUid: res.data.uid } });
            });
            return;
          }
          updatePost(this.$route.params.postUid, this.formData).then((res: any) => {
            load.hide();
            this.$router.push({ name: this.$route.name === 'AppPostUpdate' ? 'AppPostDetail' : 'PostDetail', params: { menuUid: this.$route.params.menuUid, postUid: res.data.uid } });
          }).catch((error) => {
            alert(error.response.data.message || '게시글을 수정하는데 실패했습니다.');
            load.hide();
          });
        } else {
          addPost(this.formData).then((res: any) => {
            load.hide();
            this.$router.push({ name: this.$route.name === 'AppPostAdd' ? 'AppPostDetail' : 'PostDetail', params: { menuUid: this.$route.params.menuUid, postUid: res.data.uid } });
          }).catch((error) => {
            alert(error.response.data.message || '게시글을 추가하는데 실패했습니다.');
            load.hide();
          });
        }
      }
    }, 500);
  }

  /* eslint-disable */
  private handleClickCancle() {
    if (this.$route.params.postUid) {
      this.$router.push({ name: this.$route.name === 'AppPostUpdate' ? 'AppPostDetail' : 'PostDetail', params: { menuUid: this.$route.params.menuUid, postUid: this.$route.params.postUid } });
    } else {
      this.$router.push({ name: this.$route.name === 'AppPostAdd' ? 'AppBoard' : 'BoardIndex', params: { menuUid: this.$route.params.menuUid } });
    }
  }
  /* eslint-enable */

  private handleUploadFile(data: any, index: number) {
    if (this.menu) {
      const boardField = this.menu.board.fieldList[index];
      const postFile = {
        postFilePk: {
          postUid: '' || this.$route.params.postUid,
          file: data,
        },
        fieldUid: boardField.uid,
        viewOrder: 0,
      };
      this.formData.fileList.push(postFile);
      this.formData.dataList[index].inputValue = data.uid;
    }
  }

  private getFileList(index: number) {
    if (this.menu) {
      const boardField = this.menu.board.fieldList[index];
      const fileList: any[] = [];
      this.formData.fileList.forEach((file: any) => {
        if (file.fieldUid === boardField.uid) fileList.push(file);
      });
      return fileList;
    }
    return [];
  }

  private handleRemoveFile(fileUid: string) {
    const fileIndex = this.formData.fileList.findIndex((file: any) => file.postFilePk.file.uid === fileUid);
    if (fileIndex > -1) this.formData.fileList.splice(fileIndex, 1);
  }

  private handleChangeFile(e: any) {
    const file = e.target.files[0];
    if (this.menu) {
      if (this.menu.board.fileSizeLimit * 1024 * 1024 < file.size) {
        alert(`최대 ${this.menu.board.fileSizeLimit}MB 까지 업로드 가능합니다.`);
        return;
      }
      if (this.menu.board.fileCountLimit <= this.getAttachedFileList().length) {
        alert(`최대 ${this.menu.board.fileCountLimit}개까지 업로드 가능합니다.`);
        return;
      }
    }
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    uploadFile('post', formData).then((res: any) => {
      const postFile = {
        postFilePk: {
          postUid: '' || this.$route.params.postUid,
          file: res.data,
        },
        fieldUid: null,
        viewOrder: 0,
      };
      this.formData.fileList.push(postFile);
    });
  }

  private getAttachedFileList() {
    const attachedFileList: any[] = [];
    this.formData.fileList.forEach((postFile: any) => {
      if (!postFile.fieldUid) attachedFileList.push(postFile);
    });
    return attachedFileList;
  }

  private handleDeleteFile(fileUid: string) {
    const fileIndex = this.formData.fileList.findIndex((postFile: any) => postFile.postFilePk.file.uid === fileUid);
    if (fileIndex > -1) this.formData.fileList.splice(fileIndex, 1);
  }

  private convertCategory() {
    this.formData.categoryList = [];
    if (this.selectCategory) {
      const postCategory: IPostCategory = {
        postCategoryPk: {
          postUid: this.$route.params.postUid || '',
          category: this.selectCategory,
        },
        topUid: this.selectCategory.uid,
      };
      this.formData.categoryList.push(postCategory);
    }
  }
}
