
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { uploadFile } from '@/api/attachedFile';
import { IBoardField } from '@/types';
import Summernote from '@/components/summernote/index.vue';

@Component({
  name: 'BoardField',
  components: {
    Summernote,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private boardField!: IBoardField

  @Prop({ required: true }) private fieldValue!: string

  @Prop({ required: true }) private mentoName!: string

  @Prop({ default: [] }) private fileList!: any[]

  @Watch('inputValue')
  private onInputValueChange() {
    this.$emit('change', this.inputValue);
  }

  mounted() {
    this.inputValue = this.fieldValue;
    console.log(this.mentoName);
    if (this.boardField.fieldName === '멘토' && this.mentoName !== undefined) {
      this.inputValue = this.mentoName;
    }
  }

  private inputValue: string | number = '';

  private handleChangeFile(e: any, isImageUpload: boolean) {
    const file = e.target.files[0];
    if (this.boardField.fileSizeLimit * 1024 * 1024 < file.size) {
      alert(`최대 ${this.boardField.fileSizeLimit}MB 까지 업로드 가능합니다.`);
      return;
    }
    if (this.boardField.inputLimit <= this.fileList.length) {
      alert(`최대 ${this.boardField.inputLimit}개까지 업로드 가능합니다.`);
      return;
    }
    if (this.boardField.fieldType.typeCode === 'PHOTO') {
      if (!file.type.startsWith('image')) {
        alert('이미지 형식만 업로드 가능합니다.');
        return;
      }
    }
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    uploadFile('post', formData).then((res) => {
      this.$emit('uploadFile', res.data);
    });
  }

  private handleClickRemoveFile(index: number) {
    this.$emit('removeFile', this.fileList[index].postFilePk.file.uid);
  }

  private getValidator() {
    let validators = '';
    if (this.boardField.requiredState) validators = 'required';
    return validators;
  }

  private handleChangeContents(contents: string) {
    this.inputValue = contents;
  }
}
